import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getroleContent = createAsyncThunk(
  "/role/content",
  async () => {
    const refreshResponse = await axios.get('/auth/token');
    const newToken = refreshResponse.data.akses_token;
    const config = {
      headers: {
        Authorization: `Bearer ${newToken}`,
      },
    };
    const response = await axios.get(
      "/role/Data-Role",
      config
    );
    return response.data;
  }
);

export const roleSlice = createSlice({
  name: "role",
  initialState: {
    isLoading: false,
    role: [],
  },
  reducers: {
    tambahRole: (state, action) => {
      let { newLeadObj } = action.payload;
      state.role = [...state.role, newLeadObj];
    },

    editRole: (state, action) => {
      let { index, data } = action.payload;
      const RoleIndex = state.role.findIndex(
        (role) => role.id === index
      );

      if (RoleIndex !== -1) {
        state.role[RoleIndex] = {
          ...state.role[RoleIndex],
          ...data,
        };
      }
    },

    hapusRole: (state, action) => {
      let { index } = action.payload;
      state.role.splice(index, 1);
    },
  },

  extraReducers: {
    [getroleContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getroleContent.fulfilled]: (state, action) => {
      state.role = action.payload;
      state.isLoading = false;
    },
    [getroleContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { tambahRole, editRole, hapusRole } = roleSlice.actions;

export default roleSlice.reducer;
