import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getjadwalKerjaContent = createAsyncThunk(
  "/jadwalKerja/content",
  async () => {
    const refreshResponse = await axios.get('/auth/token');
    const newToken = refreshResponse.data.akses_token;
    const config = {
      headers: {
        Authorization: `Bearer ${newToken}`,
      },
    };
    const response = await axios.get(
      "/jadwal-kerja/Data-Jadwal-Kerja",
      config
    );
    return response.data;
  }
);

export const jadwalKerjaSlice = createSlice({
  name: "jadwalKerja",
  initialState: {
    isLoading: false,
    jadwalKerja: [],
  },
  reducers: {
    tambahjadwalKerja: (state, action) => {
      let { newLeadObj } = action.payload;
      state.jadwalKerja = [...state.jadwalKerja, newLeadObj];
    },

    editjadwalKerja: (state, action) => {
      let { index, data } = action.payload;
      const userIndex = state.jadwalKerja.findIndex(
        (jadwalKerja) => jadwalKerja.id === index
      );

      if (userIndex !== -1) {
        state.jadwalKerja[userIndex] = {
          ...state.jadwalKerja[userIndex],
          ...data,
        };
      }
    },

    hapusjadwalKerja: (state, action) => {
      let { index } = action.payload;
      state.jadwalKerja.splice(index, 1);
    },
  },

  extraReducers: {
    [getjadwalKerjaContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getjadwalKerjaContent.fulfilled]: (state, action) => {
      state.jadwalKerja = action.payload;
      state.isLoading = false;
    },
    [getjadwalKerjaContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  tambahjadwalKerja,
  editjadwalKerja,
  hapusjadwalKerja,
} = jadwalKerjaSlice.actions;

export default jadwalKerjaSlice.reducer;
