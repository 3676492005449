import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getShiftContent = createAsyncThunk(
  "/shift/content",
  async () => {
    const refreshResponse = await axios.get('/auth/token');
    const newToken = refreshResponse.data.akses_token;
    const config = {
      headers: {
        Authorization: `Bearer ${newToken}`,
      },
    };
    const response = await axios.get(
      "/shift/Data-Shift",
      config
    );
    return response.data;
  }
);

export const shiftSlice = createSlice({
  name: "shift",
  initialState: {
    isLoading: false,
    shift: [],
  },
  reducers: {
    tambahShift: (state, action) => {
      let { newLeadObj } = action.payload;
      state.shift = [...state.shift, newLeadObj];
    },

    editShift: (state, action) => {
      let { index, data } = action.payload;
      const userIndex = state.shift.findIndex(
        (shift) => shift.id === index
      );

      if (userIndex !== -1) {
        state.shift[userIndex] = {
          ...state.shift[userIndex],
          ...data,
        };
      }
    },

    hapusShift: (state, action) => {
      let { index } = action.payload;
      state.shift.splice(index, 1);
    },
  },

  extraReducers: {
    [getShiftContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getShiftContent.fulfilled]: (state, action) => {
      state.shift = action.payload;
      state.isLoading = false;
    },
    [getShiftContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { tambahShift, editShift, hapusShift } =
  shiftSlice.actions;

export default shiftSlice.reducer;
