import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getPegawaiTidakAbseContent = createAsyncThunk(
  'manajemenUser/getPegawaiTidakABsen',
  async ({ page = 1, perPage = 10, search}, thunkAPI) => {
    try {
      const refreshResponse = await axios.get('/auth/token');
      const newToken = refreshResponse.data.akses_token;

      const config = {
        headers: {
          Authorization: `Bearer ${newToken}`,
        },
        params: {
          page,
          perPage,
          search
        },
      };

      // Permintaan data pengguna setelah pembaruan token
      const response = await axios.get('/dashboard/data-pegawai-tidak-terdaftar-absensi', config);

      // console.log('data dari api',response.data.allUsers );
      if(response.data.status == "error"){
        localStorage.clear();
        window.location.href = "/";
      }else{
        return {
          data: response.data.PegawaiTidakAbsen,
          totalPages: response.data.totalPages,
          currentPage: page,
        };
      }

    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPegawaiTidakAbsenSlice = createSlice({
  name: "pegawaiTidakAbsen",
  initialState: {
    isLoading: false,
    pegawaiTidakAbsensi: [],
    totalPages: 0,
    currentPage: 1,
    perPage: 10,
  },
  reducers: {
    HapusUser: (state, action) => {
      let { index } = action.payload;
      state.pegawaiTidakAbsensi.splice(index, 1);
    },
    setPerPage: (state, action) => {
        state.perPage = action.payload;
      },
  },

  extraReducers: {
    [getPegawaiTidakAbseContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getPegawaiTidakAbseContent.fulfilled]: (state, action) => {
      state.pegawaiTidakAbsensi = action.payload.data;
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.currentPage;
      state.isLoading = false;
    },
    [getPegawaiTidakAbseContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { HapusUser,  setPerPage } = getPegawaiTidakAbsenSlice.actions;

export default getPegawaiTidakAbsenSlice.reducer;
