import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const getabsenPulangContent  = createAsyncThunk(
  "/absenPulang/content",
  async () => {
    try {
    const refreshResponse = await axios.get('/auth/token');
    const newToken = refreshResponse.data.akses_token;
    const decode = jwtDecode(newToken);

    // Check if decoding was successful and userId is available
    if (!decode || !decode.userId) {
      throw new Error("Unable to decode user information.");
    }
    const config = {
      headers: {
        Authorization: `Bearer ${newToken}`,
      },
    };
    const response = await axios.get(
      `/absen/Data-AbsenPulang/${decode.userId}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching absenPulangContent:", error);
    throw error; // Propagate the error to the Redux state
  }
  }
);

export const absenPulangSlice = createSlice({
  name: "AbsenPulang",
  initialState: {
    isLoading: false,
    AbsenPulang: [],
  },
  reducers: {
    tambahabsenPulang: (state, action) => {
      let { datafordatabase } = action.payload;
      state.AbsenPulang = [...state.AbsenPulang, datafordatabase];
    },

    editabsenPulang: (state, action) => {
      let { index, data } = action.payload;
      const userIndex = state.AbsenPulang.findIndex(
        (absenPulang) => absenPulang.id === index
      );

      if (userIndex !== -1) {
        state.AbsenPulang[userIndex] = {
          ...state.AbsenPulang[userIndex],
          ...data,
        };
      }
    },

    hapusabsenPulang: (state, action) => {
      let { index } = action.payload;
      state.AbsenPulang.splice(index, 1);
    },
  },

  extraReducers: {
    [getabsenPulangContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getabsenPulangContent.fulfilled]: (state, action) => {
      state.AbsenPulang = action.payload;
      state.isLoading = false;
    },
    [getabsenPulangContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { tambahabsenPulang, editabsenPulang, hapusabsenPulang } =
  absenPulangSlice.actions;

export default absenPulangSlice.reducer;
