import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getLaporanSPTContent = createAsyncThunk(
  "/laporanSPT/content",
  async ({ page = 1, perPage = 10, search}, thunkAPI) => {
    try{
    const refreshResponse = await axios.get('/auth/token');
    const newToken = refreshResponse.data.akses_token;
    const config = {
      headers: {
        Authorization: `Bearer ${newToken}`,
      },
      params: {
        page,
        perPage,
        search
      },
    };
    const response = await axios.get(
      "/pengajuan/Data-Pengajuan",
      config
    );
    if(response.data.status == "error"){
      localStorage.clear();
      window.location.href = "/";
    }else{
      return {
        data: response.data.allPengajuan,
        totalPages: response.data.totalPages,
        currentPage: page,
      };
    }
    }catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const laporanSPTSlice = createSlice({
  name: "laporanSPT",
  initialState: {
    isLoading: false,
    laporanSPT: [],
    totalPages: 0,
    currentPage: 1,
  },
  reducers: {
    tambahLaporanSPT: (state, action) => {
      let { newLeadObj } = action.payload;
      state.laporanSPT = [...state.laporanSPT, newLeadObj];
    },

    editLaporanSPT: (state, action) => {
      let { index, data } = action.payload;
      const laporanSPTindex = state.laporanSPT.findIndex(
        (laporanSPT) => laporanSPT.id === index
      );

      if (laporanSPTindex !== -1) {
        state.laporanSPT[laporanSPTindex] = {
          ...state.laporanSPT[laporanSPTindex],
          ...data,
        };
      }
    },

    hapusLaporanSPT: (state, action) => {
      let { index } = action.payload;
      state.laporanSPT.splice(index, 1);
    },
  },

  extraReducers: {
    [getLaporanSPTContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getLaporanSPTContent.fulfilled]: (state, action) => {
      state.laporanSPT = action.payload.data;
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.currentPage;
      state.isLoading = false;
    },
    [getLaporanSPTContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { tambahLaporanSPT, editLaporanSPT, hapusLaporanSPT } =
  laporanSPTSlice.actions;

export default laporanSPTSlice.reducer;
