import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getPuskesmasContent = createAsyncThunk(
  "/puskesmas/content",
  async () => {
    const refreshResponse = await axios.get('/auth/token');
    const newToken = refreshResponse.data.akses_token;
    const config = {
      headers: {
        Authorization: `Bearer ${newToken}`,
      },
    };
    const response = await axios.get(
      "/puskesmas/Data-Puskesmas",
      config
    );
    return response.data;
  }
);

export const puskesmasSlice = createSlice({
  name: "puskesmas",
  initialState: {
    isLoading: false,
    puskesmas: [],
  },
  reducers: {
    tambahPuskesmas: (state, action) => {
      let { newLeadObj } = action.payload;
      state.puskesmas = [...state.puskesmas, newLeadObj];
    },

    editPuskesmas: (state, action) => {
      let { index, data } = action.payload;
      const userIndex = state.puskesmas.findIndex(
        (puskesmas) => puskesmas.id === index
      );

      if (userIndex !== -1) {
        state.puskesmas[userIndex] = {
          ...state.puskesmas[userIndex],
          ...data,
        };
      }
    },

    hapusPuskesmas: (state, action) => {
      let { index } = action.payload;
      state.puskesmas.splice(index, 1);
    },
  },

  extraReducers: {
    [getPuskesmasContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getPuskesmasContent.fulfilled]: (state, action) => {
      state.puskesmas = action.payload;
      state.isLoading = false;
    },
    [getPuskesmasContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { tambahPuskesmas, editPuskesmas, hapusPuskesmas } =
  puskesmasSlice.actions;

export default puskesmasSlice.reducer;
