import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getprofileContent = createAsyncThunk(
  "/profile/content",
  async (id) => {
    const refreshResponse = await axios.get('/auth/token');
    const newToken = refreshResponse.data.akses_token;
    const config = {
      headers: {
        Authorization: `Bearer ${newToken}`,
      },
    };
    const response = await axios.get(
      `/users/data-users/${id}`,
      config
    );
    return response.data.UsersById;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    isLoading: false,
    profile: [],
  },
  reducers: {
    tambahProfile: (state, action) => {
      let { newLeadObj } = action.payload;
      state.profile = [...state.profile, newLeadObj];
    },

    editProfile: (state, action) => {
      let { index, data } = action.payload;
      const ProfileIndex = state.profile.findIndex(
        (profile) => profile.id === index
      );

      if (ProfileIndex !== -1) {
        state.profile[ProfileIndex] = {
          ...state.profile[ProfileIndex],
          ...data,
        };
      }
    },

    hapusProfile: (state, action) => {
      let { index } = action.payload;
      state.profile.splice(index, 1);
    },
  },

  extraReducers: {
    [getprofileContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getprofileContent.fulfilled]: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    [getprofileContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { tambahProfile, editProfile, hapusProfile } = profileSlice.actions;

export default profileSlice.reducer;
