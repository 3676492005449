import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import puskesmasSlice from "../features/puskesmas/puskesmasSlice";
import shiftSlice from "../features/shift/shiftSlice";
import kategoriPelayananSlice from "../features/kategoriPelayanan/kategoriPelayananSlice";
import roleSlice from "../features/role/roleSlice";
import manajemenUserSlice from "../features/manajemenUser/manajemenUserSlice";
import agamaSlice from "../features/agama/agamaSlice";
import jadwalKerjaSlice from "../features/jadwalKerja/jadwalKerjaSlice";
import bukuTamuSlice from "../features/bukuTamu/bukuTamuSlice";
import absenMasukSlice from "../features/absenMasuk/absenMasukSlice";
import absenPulangSlice from "../features/absenPulang/absenPulangSlice";
import profileSlice from "../features/settings/profilesettings/profileSlice";
import laporanSPTSlice from "../features/laporanspt/laporanSPTSlice";
import PegawaiTidakAbsenSlice from '../features/PegawaiTidakAbsen/PegawaiTidakAbsenSlice';

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  datamanajemenUser: manajemenUserSlice,
  puskesmas: puskesmasSlice,
  role: roleSlice,
  shift: shiftSlice,
  kategoriPelayanan: kategoriPelayananSlice,
  agama: agamaSlice,
  jadwalKerja: jadwalKerjaSlice,
  bukuTamu: bukuTamuSlice,
  AbsenMasuk : absenMasukSlice,
  AbsenPulang : absenPulangSlice,
  Profile : profileSlice,
  LaporanSPT : laporanSPTSlice,
  pegawaiTidakAbsen : PegawaiTidakAbsenSlice
};

export default configureStore({
  reducer: combinedReducer,
});
