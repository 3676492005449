import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getagamaContent = createAsyncThunk(
  "agama/content",
  async () => {
    const refreshResponse = await axios.get('/auth/token');
    const newToken = refreshResponse.data.akses_token;
    const config = {
      headers: {
        Authorization: `Bearer ${newToken}`,
      },
    };
    const response = await axios.get(
      "/agama/Data-Agama",
      config
    );
    return {data : response.data};
  }
);

export const agamaSlice = createSlice({
  name: "agama",
  initialState: {
    isLoading: false,
    agama: [],
  },
  reducers: {
    tambahagama: (state, action) => {
      let { datafordatabase } = action.payload;
      state.agama = [...state.agama, datafordatabase];
    },

    editagama: (state, action) => {
      let { index, data } = action.payload;
      const userIndex = state.agama.findIndex(
        (agama) => agama.id === index
      );

      if (userIndex !== -1) {
        state.agama[userIndex] = {
          ...state.agama[userIndex],
          ...data,
        };
      }
    },

    hapusagama: (state, action) => {
      let { index } = action.payload;
      state.agama.splice(index, 1);
    },
  },

  extraReducers: {
    [getagamaContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getagamaContent.fulfilled]: (state, action) => {
      state.agama = action.payload.data;
      state.isLoading = false;
    },
    [getagamaContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { tambahagama, editagama, hapusagama } =
  agamaSlice.actions;

export default agamaSlice.reducer;
