import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getmanajemenUserContent = createAsyncThunk(
  'manajemenUser/getContent',
  async ({ page = 1, perPage = 10, search}, thunkAPI) => {
    try {
      const refreshResponse = await axios.get('/auth/token');
      const newToken = refreshResponse.data.akses_token;

      const config = {
        headers: {
          Authorization: `Bearer ${newToken}`,
        },
        params: {
          page,
          perPage,
          search
        },
      };

      // Permintaan data pengguna setelah pembaruan token
      const response = await axios.get('/users/data-users', config);

      // console.log('data dari api',response.data.allUsers );
      if(response.data.status == "error"){
        localStorage.clear();
        window.location.href = "/";
      }else{
        return {
          data: response.data.allUsers,
          totalPages: response.data.totalPages,
          currentPage: page,
        };
      }

    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const manajemenUserSlice = createSlice({
  name: "manajemenUser",
  initialState: {
    isLoading: false,
    manajemenUser: [],
    totalPages: 0,
    currentPage: 1,
  },
  reducers: {
    tambahUser: (state, action) => {
      let { datafordatabase } = action.payload;
      state.manajemenUser = [...state.manajemenUser, datafordatabase];
    },

    editUser: (state, action) => {
      let { index, data } = action.payload;
      const userIndex = state.manajemenUser.findIndex(
        (manajemenUser) => manajemenUser.id === index
      );

      if (userIndex !== -1) {
        state.manajemenUser[userIndex] = {
          ...state.manajemenUser[userIndex],
          ...data,
        };
      }
    },

    HapusUser: (state, action) => {
      let { index } = action.payload;
      state.manajemenUser.splice(index, 1);
    },
  },

  extraReducers: {
    [getmanajemenUserContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getmanajemenUserContent.fulfilled]: (state, action) => {
      state.manajemenUser = action.payload.data;
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.currentPage;
      state.isLoading = false;
    },
    [getmanajemenUserContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { tambahUser, editUser, HapusUser } = manajemenUserSlice.actions;

export default manajemenUserSlice.reducer;
