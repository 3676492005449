import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const getabsenMasukContent  = createAsyncThunk(
  "/absenMasuk/content",
  async () => {
    try {
    const refreshResponse = await axios.get('/auth/token');
    const newToken = refreshResponse.data.akses_token;
    const decode = jwtDecode(newToken);

    // Check if decoding was successful and userId is available
    if (!decode || !decode.userId) {
      throw new Error("Unable to decode user information.");
    }
    const config = {
      headers: {
        Authorization: `Bearer ${newToken}`,
      },
    };
    const response = await axios.get(
      `/absen/Data-AbsenMasuk/${decode.userId}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching absenMasukContent:", error);
    throw error; // Propagate the error to the Redux state
  }
  }
);

export const absenMasukSlice = createSlice({
  name: "AbsenMasuk",
  initialState: {
    isLoading: false,
    AbsenMasuk: [],
  },
  reducers: {
    tambahabsenMasuk: (state, action) => {
      let { datafordatabase } = action.payload;
      state.AbsenMasuk = [...state.AbsenMasuk, datafordatabase];
    },

    editabsenMasuk: (state, action) => {
      let { index, data } = action.payload;
      const userIndex = state.AbsenMasuk.findIndex(
        (absenMasuk) => absenMasuk.id === index
      );

      if (userIndex !== -1) {
        state.AbsenMasuk[userIndex] = {
          ...state.AbsenMasuk[userIndex],
          ...data,
        };
      }
    },

    hapusabsenMasuk: (state, action) => {
      let { index } = action.payload;
      state.AbsenMasuk.splice(index, 1);
    },
  },

  extraReducers: {
    [getabsenMasukContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getabsenMasukContent.fulfilled]: (state, action) => {
      state.AbsenMasuk = action.payload;
      state.isLoading = false;
    },
    [getabsenMasukContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { tambahabsenMasuk, editabsenMasuk, hapusabsenMasuk } =
  absenMasukSlice.actions;

export default absenMasukSlice.reducer;
